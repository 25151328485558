import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { FC, useState } from 'react';

type TextFieldPasswordProps = TextFieldProps & {};

const TextFieldPassword: FC<TextFieldPasswordProps> = ({ ...props }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <TextField
      variant="outlined"
      fullWidth
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default TextFieldPassword;
