import { STATUS_CODE } from "./statusCode";

/**
 * The format is json object
 * 1. You can get the msg by pass the error code as the key
 */
export const ERR_CODE_MSG = {
    [STATUS_CODE.USER_IS_DEACTIVATED]: 'This email is deactivated, please contact support !',
    [STATUS_CODE.USER_NOT_EXIST]: 'This email is not exist, please sign up !',
    [STATUS_CODE.USER_IS_EXIST]: 'This email is exist, please sign in !',
    [STATUS_CODE.SIGNUP_SUCCESS]: 'Sign Up success, login and enjoy !',
    [STATUS_CODE.ERROR]: 'Error occured, please try again!',
}