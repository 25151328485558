import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
  Alert,
  Button,
  Chip,
  Divider,
  FormHelperText,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import { API_AUTH_GS_V1, ERR_CODE_MSG, STATUS_CODE } from '../../constant';
import { TextFieldPassword } from '../../components';

export const SignUp = () => {
  // If the screen go smaller than 500px it will return false
  const sm = useMediaQuery('(min-width: 550px)');

  const query = window.location.search;
  const queryObject = new URLSearchParams(query);
  const signUpStatus = queryObject.get('signUp');
  const originURL = queryObject.get('origin');
  const getErrCode = queryObject.get('errCode');

  const errorCode = getErrCode ? parseInt(getErrCode) : STATUS_CODE.ERROR;

  const errorMsg = ERR_CODE_MSG[errorCode];

  const email = useRef<any>(null);
  const password = useRef<any>(null);

  const [signUpLoading, setSignUpLoading] = useState<boolean>(false);

  const [errMsg, setErrMsg] = useState<string | null>(null);

  const successSignUpMessage = 'Sign Up success';

  const isSignUpSuccess = signUpStatus === 'true' ? true : false;
  const isSignUpStatusExist = signUpStatus ? true : false;

  const redirectToLoginPage = (originURL: string | null) => {
    if (originURL) {
      setTimeout(() => {
        window.location.href = originURL;
      }, 2000);
    }
  };

  const signUp = async (evt: any) => {
    evt.preventDefault();

    setSignUpLoading(true);

    try {
      await axios.post(`${API_AUTH_GS_V1}auth/signUp/platform`, {
        email: email.current,
        password: password.current,
        originURL: originURL,
      });

      setErrMsg(null);
      setSignUpLoading(false);

      redirectToLoginPage(originURL);
    } catch (err: any) {
      setSignUpLoading(false);
      setErrMsg('Sign up error, please try again in a moment');

      const errInfo = err?.response.data?.desc;

      if (errInfo?.status) {
        if (errInfo.status === STATUS_CODE.USER_IS_EXIST) {
          setErrMsg('Email already exist, please continue to sign in');
        }
      }
    }
  };

  useEffect(() => {
    // Trigger the google function
    try {
      window.initGoogleAuth();
    } catch (e) {
      console.log('Google Authentication Not Supported ');
    }
  }, []);

  useEffect(() => {
    if (isSignUpSuccess) {
      redirectToLoginPage(originURL);
    }
  }, [isSignUpSuccess, originURL]);

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {(errMsg !== null || !isSignUpSuccess) && isSignUpStatusExist && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorCode ? errorMsg : errMsg}
        </Alert>
      )}

      {isSignUpSuccess && (
        <>
          <Alert severity="success" sx={{ mb: 2 }}>
            {successSignUpMessage}
          </Alert>
          {originURL && (
            <Typography sx={{ mb: 2, color: '#797878', fontSize: 13 }}>
              REDIRECTING NOW...
            </Typography>
          )}
        </>
      )}

      <Box
        sx={{
          maxWidth: 380,
          minWidth: 350,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: 4,
          }}
          style={sm === false ? { boxShadow: 'none' } : {}}
        >
          <>
            <Typography variant="h5" component="p">
              Sign Up
            </Typography>

            <form onSubmit={signUp}>
              <TextField
                ref={email}
                name="email"
                id="email"
                label="Email"
                variant="outlined"
                fullWidth
                required
                type={'email'}
                sx={{
                  marginBottom: 3,
                  marginTop: 3,
                }}
                onChange={(evt) => {
                  email.current = evt.target.value;
                }}
              />

              <TextFieldPassword
                name="password"
                id="password"
                label="Password"
                variant="outlined"
                fullWidth
                type={'password'}
                required
                inputProps={{
                  minLength: 8,
                  maxLength: 100,
                }}
                onChange={(evt) => {
                  password.current = evt.target.value;
                }}
              />

              <FormHelperText
                sx={{
                  marginTop: 2,
                  color: 'red',
                }}
              ></FormHelperText>

              <Button
                variant="contained"
                fullWidth
                type="submit"
                sx={{
                  marginTop: 3,
                  marginBottom: 5,
                }}
                disabled={signUpLoading}
              >
                Sign up
              </Button>

              {signUpLoading && (
                <Box sx={{ width: '100%', mb: 4, mt: -3 }}>
                  <LinearProgress sx={{ height: 2 }} />
                </Box>
              )}
            </form>

            <Divider
              sx={{
                marginBottom: 3,
              }}
            >
              <Chip label="OR" />
            </Divider>

            <Typography variant="h6" sx={{ fontSize: 14 }}>
              Sign up with Google
            </Typography>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                id="google_btn"
                style={{
                  cursor: 'pointer',
                  padding: 10,
                  borderRadius: 5,
                }}
              >
                Google Login
              </div>
            </Box>
          </>
        </Paper>
      </Box>

      {originURL && (
        <Typography sx={{ color: '#565656', fontSize: 14, mt: 2 }}>
          Already have account,{' '}
          <a
            href={originURL}
            style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}
          >
            Sign In
          </a>{' '}
          now
        </Typography>
      )}
    </Box>
  );
};
