import { createBrowserRouter } from 'react-router-dom';
import {
  AuthMainLayout,
  ForgotPassword,
  Login,
  ResetPassword,
  SignUp,
  FirstTimeLogin,
  SuccessPage,
} from '../modules/Auth';
import { CreatePassword } from '../modules/Auth/CreatePassword';

export const routerList = createBrowserRouter([
  {
    path: '/',
    element: <AuthMainLayout />,
    children: [
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'sign-up',
        element: <SignUp />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
      {
        path: 'first-time-login',
        element: <FirstTimeLogin />,
      },
      {
        path: 'success-page',
        element: <SuccessPage />,
      },
      {
        path: 'create-password',
        element: <CreatePassword />,
      },
    ],
  },
]);
