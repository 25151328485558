import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  LinearProgress,
  Modal,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  InputAdornment,
  IconButton,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { TextFieldPassword } from '../../components';

export const CreatePassword = () => {
  const sm = useMediaQuery('(min-width: 550px)');

  const navigate = useNavigate();

  const password = useRef<any>(null);
  const confirmPassword = useRef<any>(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [resetPassFailError, setResetPassFailError] = useState('');

  const [forgotPasswordLoading, setForgotPasswordLoading] = useState<boolean>(false);
  const [googleLoginEnabled, setGoogleLoginEnabled] = useState<any>(true);
  const [normalLoginEnabled, setNormalLoginEnabled] = useState<boolean>(true);

  const query = window.location.search;
  const queryObject = new URLSearchParams(query);
  const token = queryObject.get('token');
  const email = queryObject.get('email');
  const normalLogin = queryObject.get('normalLogin');
  const googleLogin = queryObject.get('googleLogin');
  const canSignUp = queryObject.get('newAcc');
  const continueURL = queryObject.get('continue');
  const originURLQuery = queryObject.get('origin');
  const projectID = queryObject.get('id');

  const [originURL, setOriginURL] = useState<string | null>(null);

  // Inititally we don't want to show the alert msg, hence default value is null
  const [resetPassSuccess, setResetPassSuccess] = useState<boolean | null>(null);
  const validateInput = () => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]+$/;
    let isError = false;
    if (!password.current || password.current === '') {
      setPasswordError('Please enter the Password');
      isError = true;
    }
    if (!confirmPassword.current || confirmPassword.current === '') {
      setConfirmPasswordError('Please enter the Confirm Password');
      isError = true;
    } else if (confirmPassword.current !== password.current) {
      setConfirmPasswordError('Password does not match');
      isError = true;
    }

    // Minimum of 15 characters long
    else if (password.current.length < 15) {
      setPasswordError(' Password does not meet minimum requirements');
      isError = true;
    }

    // Use a mix of upper and lowercase letters, numbers, and symbols
    else if (!passwordRegex.test(password.current)) {
      setPasswordError(' Password does not meet minimum requirements');
      isError = true;
    }

    if (isError === true) {
      return false;
    }
    setConfirmPasswordError('');
    setPasswordError('');
    return true;
  };
  const resetThePassword = (evt: any) => {
    evt.preventDefault();

    if (validateInput()) {
      setForgotPasswordLoading(true);

      axios
        .post(
          `${process.env.REACT_APP_AUTH_GS_BASE_URL}/api/v1/auth/reset-password?token=${token}`,
          {
            password: password.current,
            confirmPassword: confirmPassword.current,
          },
        )
        .then((res) => {
          const data = res.data;

          setOriginURL(data.originURL);

          navigate(`/success-page`);
          // if (data.originURL) {
          //   setTimeout(() => {
          //     window.location.href = data.originURL;
          //   }, 2000);
          // }
          setResetPassSuccess(true);
          setResetPassFailError('');
        })
        .catch((err) => {
          // setResetPassSuccess(false);

          setResetPassFailError(
            ' Reset password failed, please contact your administrator ',
          );
        })
        .finally(() => {
          setForgotPasswordLoading(false);
        });
    }
  };

  const [showErrModal, setShowErrModal] = useState<boolean>(false);

  useEffect(() => {
    if (token === null) {
      // setShowErrModal(true);
    } else {
      setShowErrModal(false);
    }
  }, [token]);

  useEffect(() => {
    // Trigger the google function
    // Check if queryObject has values and they are false
    if (googleLogin === 'false') {
      setGoogleLoginEnabled(false);
    }

    if (normalLogin === 'false') {
      setNormalLoginEnabled(false);
    }

    if (googleLogin === 'false' && normalLogin === 'false') {
      setGoogleLoginEnabled(true); // You can choose to set the other one to true as well
    }
    try {
      window.initGoogleAuth();
      setGoogleLoginEnabled(true);
    } catch (e) {
      console.log('Google Authentication Not Supported ');
      setGoogleLoginEnabled(false);
      setNormalLoginEnabled(true);
    }
  }, [googleLogin, normalLogin]);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const boxStyle = {
    boxShadow: 'none', // This removes the border
  };
  return (
    <>
      <Box
        sx={{
          height: '80vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Modal
          open={showErrModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Error
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Token is required, there is no token in URL
            </Typography>

            <Typography
              sx={{ fontSize: 11, mt: 2, fontFamily: 'monospace', color: '#565656' }}
            >
              ** This error cannot be closed, close the tab instead or use the correct url
            </Typography>
          </Box>
        </Modal>

        {resetPassSuccess && (
          <>
            <Alert sx={{ mb: 2 }} severity="success">
              Reset password success
              {originURL === null && ', you can proceed to login in your website'}{' '}
            </Alert>
            {originURL && (
              <Typography sx={{ mb: 2, color: '#797878', fontSize: 13 }}>
                REDIRECTING NOW...
              </Typography>
            )}
          </>
        )}

        <Paper
          elevation={3}
          sx={{
            padding: 4,
          }}
          style={boxStyle}
        >
          <>
            <div>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div>
                  <Typography fontWeight={'bold'} fontSize={24} mb={5} component="p">
                    Create Password
                  </Typography>
                </div>

                {email && (
                  <>
                    <Typography sx={{ fontSize: 14, mb: 1 }}>
                      Your Registered Email
                    </Typography>
                    <Typography sx={{ fontSize: 14, mb: 1 }} fontWeight="bold">
                      {email}
                    </Typography>
                  </>
                )}

                {googleLoginEnabled !== false && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <div
                      id="google_btn"
                      style={{
                        cursor: 'pointer',
                        padding: 20,
                        borderRadius: 5,
                      }}
                    >
                      Google Login
                    </div>
                  </Box>
                )}
              </Box>

              {googleLoginEnabled !== false && normalLoginEnabled !== false && (
                <Divider
                  sx={{
                    marginBottom: 3,
                  }}
                >
                  or
                  {/* <Chip label="OR" /> */}
                </Divider>
              )}

              {/* <Typography fontSize={14} mt={5} color={'#333333'} component="p">
                Your Registered Email
              </Typography>
              <Typography
                fontSize={14}
                mb={5}
                fontWeight={'bold'}
                color={'#333333'}
                component="p"
              >
                Email
              </Typography> */}
            </div>
            <form onSubmit={resetThePassword}>
              <Typography sx={{ fontSize: 16, mb: 1 }}>Create New Password</Typography>

              <TextFieldPassword
                name="password"
                id="password"
                variant="outlined"
                fullWidth
                error={passwordError ? true : false}
                helperText={passwordError ? passwordError : ''}
                onChange={(evt) => {
                  password.current = evt.target.value;
                  setPasswordError('');
                }}
              />
              {passwordError !== '' && (
                <Typography sx={{ fontSize: 12, mb: 1, mt: 3, color: '#838383' }}>
                  • Minimum of 15 characters long<br></br>• Use a mix of upper and
                  lowercase letters, numbers, and symbols
                </Typography>
              )}
              <Typography sx={{ fontSize: 16, mb: 1, mt: 3 }}>
                Confirm New Password
              </Typography>
              <TextFieldPassword
                name="confrim-password"
                id="confirm-password"
                variant="outlined"
                fullWidth
                error={confirmPasswordError ? true : false}
                helperText={confirmPasswordError ? confirmPasswordError : ''}
                onChange={(evt) => {
                  confirmPassword.current = evt.target.value;
                  setConfirmPasswordError('');
                }}
              />

              <Button
                variant="contained"
                fullWidth
                type="submit"
                sx={{
                  marginTop: 5,
                  marginBottom: 2,
                  height: 56,
                  backgroundColor: '#088FD1',
                  textTransform: 'none',
                }}
                disabled={forgotPasswordLoading}
              >
                Create Password
              </Button>

              {/* TODO > show this loading when click send link button above  */}

              {forgotPasswordLoading && (
                <Box sx={{ width: '100%', mb: 4, mt: -3 }}>
                  <LinearProgress sx={{ height: 2 }} />
                </Box>
              )}

              {resetPassFailError !== null && resetPassFailError !== '' && (
                <Typography sx={{ mb: 2, color: '#E95048', fontSize: 13 }}>
                  Reset password failed, please contact your administrator{' '}
                </Typography>
              )}
            </form>
          </>
        </Paper>
      </Box>{' '}
    </>
  );
};
