import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  LinearProgress,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import axios from 'axios';
import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';

export const FirstTimeLogin = () => {
  const sm = useMediaQuery('(min-width: 550px)');
  const navigate = useNavigate();

  const [error, setError] = useState('');
  const [isVerified, setVerified] = useState(false);

  const query = window.location.search;
  const queryObject = new URLSearchParams(query);

  const originURL = queryObject.get('origin');

  // TODO, the origin url is not required to reset the password, but if we want to redirect the user, we need that url

  const email = useRef<any>(null);

  const [forgotPasswordLoading, setForgotPasswordLoading] = useState<boolean>(false);
  const [sendPasswordSuccess, setSendPasswordSuccess] = useState<boolean | null>(null);
  const handleRecaptchaChange = () => {
    // This function will be called when reCAPTCHA is verified
    setVerified(true);
  };
  const validateInput = () => {
    if (!email.current || email.current === '') {
      setError('Please enter the Username');
      return false;
    }

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.current)) {
      setError('Please insert a valid email address');
      return false;
    }

    setError('');
    return true;
  };
  const sendFirstTimeLoginLink = (evt: any) => {
    evt.preventDefault();

    if (validateInput() && isVerified == true) {
      // Proceed with form submission or
      setForgotPasswordLoading(true);

      axios
        .post(`${process.env.REACT_APP_AUTH_GS_BASE_URL}/api/v1/auth/first-time-login`, {
          email: email.current,
          originURL: originURL,
        })
        .then((res) => {
          //setSendPasswordSuccess(true);
          navigate(`/success-page?from=verify&origin=${originURL}`);
        })
        .catch((err) => {
          let error_message =
            'No account found with this email. Try a different email or contact your administrator';
          if (err?.response?.data) {
            const errData = err.response.data;
            if (errData?.detail?.msg) {
              error_message = errData.detail.msg;
            }
          }
          setError(error_message);
          //   setSendPasswordSuccess(false);
        })
        .finally(() => {
          setForgotPasswordLoading(false);
        });
    }
  };

  const boxStyle = {
    boxShadow: 'none', // This removes the border
  };
  const isDisableIfSendPasswordLinkSuccess =
    sendPasswordSuccess === null ? false : sendPasswordSuccess;
  const showSendLinkBtn = sendPasswordSuccess !== true;

  return (
    <>
      <Box
        sx={{
          height: '80vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 4,
        }}
      >
        {sendPasswordSuccess === true ? (
          <Alert severity="success" sx={{ mb: 2 }}>
            Reset password link sent, check your inbox!
          </Alert>
        ) : (
          sendPasswordSuccess === false && (
            <Alert severity="error" sx={{ mb: 2 }}>
              Failed to send password link, please try again!
            </Alert>
          )
        )}
        <div>
          <Typography fontSize={24} fontWeight={'bold'} alignSelf={'left'} component="p">
            First time login?{' '}
          </Typography>

          <Typography fontSize={14} component="p">
            Enter your organization email address to get started. We will send you a link
            to click to confirm your account via email.
          </Typography>
        </div>
        <Box>
          <Paper
            elevation={3}
            sx={{
              padding: 4,
            }}
            style={boxStyle}
          >
            <>
              <form onSubmit={sendFirstTimeLoginLink}>
                <Typography sx={{ fontSize: 16, mb: 1 }}> Organization Email</Typography>{' '}
                <TextField
                  //   ref={email}
                  name="email"
                  id="email"
                  variant="outlined"
                  fullWidth
                  error={error ? true : false}
                  helperText={error ? error : ''}
                  disabled={isDisableIfSendPasswordLinkSuccess}
                  onChange={(evt) => {
                    email.current = evt.target.value;

                    setError('');
                  }}
                />
                {showSendLinkBtn && (
                  <>
                    {' '}
                    <div style={{ paddingTop: '20px', width: '100%' }}>
                      <ReCAPTCHA
                        sitekey="6Lflhg4pAAAAACyxCbvGMmGVrSs43olUhC-972RG"
                        onChange={handleRecaptchaChange}
                      ></ReCAPTCHA>
                    </div>
                    <Button
                      variant="contained"
                      fullWidth
                      type="submit"
                      sx={{
                        marginTop: 3,
                        marginBottom: 5,
                        height: 56,
                        backgroundColor: '#088FD1',
                      }}
                      disabled={forgotPasswordLoading || !isVerified}
                    >
                      Verify Account
                    </Button>
                  </>
                )}
                {/* TODO > show this loading when click send link button above  */}
                {forgotPasswordLoading && (
                  <Box sx={{ width: '100%', mb: 4, mt: -3 }}>
                    <LinearProgress sx={{ height: 2 }} />
                  </Box>
                )}
              </form>
            </>
          </Paper>
        </Box>
        <Typography variant="h6" sx={{ fontSize: 12, fontWeight: 400 }}>
          By entering your email, you confirm to have read ELEVADE’s{' '}
          <a href="https://ade.aero/privacy-statement" style={{ textDecoration: 'none' }}>
            <span style={{ color: '#00A8E8' }}>Privacy Policy </span>
          </a>
          and agree to the{' '}
          <a
            href="https://ade.aero/terms-and-conditions"
            style={{ textDecoration: 'none' }}
          >
            <span style={{ color: '#00A8E8' }}>Terms of Use</span>
          </a>
        </Typography>{' '}
        {/* NOTE: this should be from login button from the Application that subscribe to AUTH GS */}
        {/* <Typography sx={{ color: '#565656', fontSize: 14, mt: 2 }}>Already have account,  <a href={`/login${query}&type=SIGNUP`} style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}>Sign In</a> now</Typography> */}
      </Box>
    </>
  );
};
