/**
 * Get the status code from all the integrated api providers
 */
export const STATUS_CODE = {

    // Success
    SIGNUP_SUCCESS: 211,

    // Error
    CONTINUE_URL_NOT_VALID: 441,
    USER_NOT_EXIST: 443,
    USER_IS_DEACTIVATED: 444,
    USER_IS_EXIST: 445,

    // Default Error
    ERROR: 456
}