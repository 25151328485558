import { Alert, Box, Button, Paper, Typography } from '@mui/material';
import SuccessEmail from '../../utils/images/SuccessEmail.png';
import { useNavigate } from 'react-router-dom';
export const SuccessPage = () => {
  const query = window.location.search;
  const queryObject = new URLSearchParams(query);
  const navigate = useNavigate();
  const originURL = queryObject.get('origin');
  const continueURL = queryObject.get('continue');
  const from = queryObject.get('from');
  const projectID = queryObject.get('id');

  const navigateToLogin = () => {
    if (continueURL) {
      navigate(
        `/login?origin=${originURL}&continue=${continueURL}&id=${projectID}&version=v2`,
      );
    } else {
      navigate(`/login`);
    }
  };

  const boxStyle = {
    boxShadow: 'none', // This removes the border
  };

  return (
    <>
      <Box
        sx={{
          height: '80vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: 4,
          }}
          style={boxStyle}
        >
          <>
            <img src={SuccessEmail} alt="Elevade Email Success"></img>
            {from === 'verify' ? (
              <>
                <Typography sx={{ fontSize: 24, fontWeight: 'Bold', mb: 1 }}>
                  Verification email sent!
                </Typography>
                <Typography sx={{ fontSize: 16, mb: 1 }}>
                  We've sent you a link to verify your account. Please click on the link
                  in your email to get started.
                </Typography>
              </>
            ) : (
              <>
                <Typography sx={{ fontSize: 24, fontWeight: 'Bold', mb: 1 }}>
                  Password {from === 'verify' ? 'updated' : 'created'}
                </Typography>
                <Typography sx={{ fontSize: 16, mb: 1 }}>
                  Success! You have created a new password for ELEVADE.
                  <br />
                  Please use your new password to log in to your account.
                </Typography>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    marginTop: 3,
                    marginBottom: 5,
                    maxWidth: '400px',
                    height: 56,
                    backgroundColor: '#088FD1',
                  }}
                  onClick={navigateToLogin}
                >
                  {from === 'verify' ? 'Go to Main Page' : 'Login Now'}
                </Button>
              </>
            )}
          </>
        </Paper>
      </Box>
    </>
  );
};
